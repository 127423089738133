<template>
  <b-card>
    <b-overlay :variant="skin" :show="isloading" spinner-variant="primary" spinner-type="grow">
      <Table
        :fields="fields"
        :list="list"
        @orderBy="getRecordsOrderBy"
        border="full"
        style="font-size: 10px; min-height: 80vh"
        class="text-nowrap mb-0"
        responsive
      >
        <template #appointment_id="data">
          <div
            :data-text="data.item.appointment_id"
            @click="appointmentOpen(data.item.appointment_id)"
          >
            {{ data.item.appointment_id }}
          </div>
        </template>
        <template #operator_name="data">
          <div
            v-if="
              $utils.isPermited('permission.appointment.studio.operator.edit')
            "
          >
            <b-row v-if="data.item.operator_id && data.item.operator_id > 0">
              <b-col cols="auto" class="cont-operator">
                {{ data.item.operator_name }}
                <feather-icon
                  :id="`operators-row-${data.item.appointment_studio_id}`"
                  icon="XIcon"
                  class="del-icon"
                  @click="onBlurBackoffice(data.item, false, true)"
                />
                <feather-icon
                  v-if="data.item.has_operator_change"
                  icon="RepeatIcon"
                  class="alert-icon"
                  size="16"
                />
              </b-col>
            </b-row>
            <div class="text-nowrap" v-else>
              <b-form-group class="mb-0">
                <v-select
                  v-if="data.item.operators"
                  v-model="data.item.operatorsSelected"
                  :options="data.item.operators"
                  autocomplete="off"
                  :clearable="false"
                  @input="onBlurBackoffice(data.item, false, false)"
                />
              </b-form-group>
            </div>
          </div>
          <div v-else>
            <div
              class="text-nowrap"
              v-if="data.item.operator_id && data.item.operator_id > 0"
            >
              {{ data.item.operator_name }}
            </div>
            <div v-else>**********</div>
          </div>
        </template>
        <template #operator_backup_name="data">
          <div
            v-if="
              $utils.isPermited('permission.appointment.studio.operator.edit')
            "
          >
            <b-row
              v-if="
                data.item.operator_backup_id && data.item.operator_backup_id > 0
              "
            >
              <b-col cols="auto" class="cont-operator">
                {{ data.item.operator_backup_name }}
                <feather-icon
                  :id="`operators-row-${data.item.appointment_studio_id}`"
                  icon="XIcon"
                  class="del-icon"
                  @click="onBlurBackoffice(data.item, true, true)"
                />
                <feather-icon
                  v-if="data.item.has_operator_change"
                  icon="RepeatIcon"
                  class="alert-icon"
                  size="16"
                />
              </b-col>
            </b-row>
            <div class="text-nowrap" v-else>
              <b-form-group class="mb-0">
                <v-select
                  v-if="data.item.operators"
                  v-model="data.item.operatorsBackupSelected"
                  :options="data.item.operators"
                  autocomplete="off"
                  :clearable="false"
                  @input="onBlurBackoffice(data.item, true, false)"
                />
              </b-form-group>
            </div>
          </div>
          <div v-else>
            <div
              class="text-nowrap"
              v-if="
                data.item.operator_backup_id && data.item.operator_backup_id > 0
              "
            >
              {{ data.item.operator_backup_name }}
            </div>
            <div v-else>**********</div>
          </div>
        </template>
        <template #course_event="data">
          <div
            :data-text="data.item.course_event"
            class="text-truncate ellipsis"
          >
            {{ data.item.course_event }}
          </div>
        </template>
        <template #studio_name="data">
          <div
            v-if="
              $utils.isPermited('permission.appointment.studio.edit') &&
              data.item.studio_main
            "
          >
            <b-row v-if="data.item.studio_id && data.item.studio_id > 0">
              <b-col cols="auto" class="cont-studio">
                {{ data.item.studio_name }}
                <feather-icon
                  :id="`studios-row-${data.item.appointment_studio_id}`"
                  icon="XIcon"
                  class="del-icon"
                  @click="onBlurStudio(data.item)"
                />
              </b-col>
            </b-row>
            <div class="text-nowrap" v-else>
              <b-form-checkbox
                class="custom-control-success mt-1"
                name="check-button"
                switch
                v-model="ignorePreparation"
                stacked
                >Ignorar preparação
              </b-form-checkbox>
              <b-form-group class="mb-0">
                <v-select
                  v-model="data.item.studioSelected"
                  :options="studios"
                  autocomplete="off"
                  @search="
                    (res) => {
                      searchStudios(res, data.item);
                    }
                  "
                  :clearable="false"
                  @input="onBlurStudioChange(data.item)"
                  :loading="isLoadingStudio"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      Pesquisando... <em>{{ search }}</em>
                    </template>
                    <em style="opacity: 0.5" v-else>Digite o estúdio</em>
                  </template>
                </v-select>
              </b-form-group>
            </div>
          </div>
          <div v-else>
            <div
              class="text-nowrap"
              v-if="data.item.studio_id && data.item.studio_id > 0"
            >
              {{ data.item.studio_name }}
            </div>
            <div v-else>**********</div>
          </div>
        </template>
        <template #company="data">
          <div
            :data-text="data.item.company"
            class="text-truncate ellipsis"
            style="max-width: 200px"
          >
            {{ data.item.company }}
          </div>
        </template>
      </Table>
      <div v-if="more" class="d-flex justify-content-center mt-2">
        <b-button @click="getLoadMore" variant="secondary" pill>
          Carregar Mais
        </b-button>
      </div>

      <!-- Change Studio -->
      <b-button
        v-if="$utils.isPermited('permission.appointment.studio.edit')"
        class="btn-change-studio btn-icon rounded-circle"
        :variant="containsFilter ? 'gradient-warning' : 'gradient-info'"
        @click="isActiveChangeStudio = true"
      >
        <feather-icon icon="RefreshCwIcon" size="25" />
      </b-button>
      <b-sidebar
        sidebar-class="sidebar-lg"
        bg-variant="white"
        v-model="isActiveChangeStudio"
        right
        backdrop
        shadow
        no-header
      >
        <template #default="{ hide }" v-if="isActiveChangeStudio">
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              content-sidebar-header
              px-2
              py-1
            "
          >
            <h5 class="mb-0">
              Escolha os agendamentos para troca de estúdios.
            </h5>
            <div>
              <feather-icon
                class="ml-1 cursor-pointer"
                icon="XIcon"
                size="16"
                @click="hide"
              />
            </div>
          </div>
          <b-form class="px-1 mt-1">
            <b-row>
              <b-col cols="12">
                <b-form-group label="Agendamento selecionado">
                  <v-select
                    v-if="appointmentsFirst.length > 0"
                    v-model="appointmentFirstSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="appointmentsFirst"
                    autocomplete="off"
                    :clearable="false"
                  >
                    <template v-slot:option="option">
                      <div class="d-flex justify-content-between">
                        <h5 class="mb-0 text-uppercase">
                          {{ option.label }}
                        </h5>
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Agendamento para troca">
                  <v-select
                    v-if="appointmentsSecond.length > 0"
                    v-model="appointmentSecondSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="appointmentsSecond"
                    autocomplete="off"
                    :clearable="false"
                  >
                    <template v-slot:option="option">
                      <div class="d-flex justify-content-between">
                        <h5 class="mb-0 text-uppercase">
                          {{ option.label }}
                        </h5>
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-checkbox
                  class="custom-control-success mb-1"
                  name="check-button"
                  switch
                  v-model="ignorePreparationCS"
                  stacked
                  >Ignorar Preparação
                </b-form-checkbox>
              </b-col>
              <b-col cols="9">
                <b-button @click="changeStudio" variant="gradient-info" block>
                  Alterar
                </b-button>
              </b-col>
              <b-col cols="3">
                <b-button
                  @click="clearChangeStudio"
                  variant="gradient-primary"
                  block
                >
                  Limpar
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </template>
      </b-sidebar>

      <!-- Filter -->
      <b-button
        class="btn-filter-appoitment btn-icon rounded-circle"
        :variant="containsFilter ? 'gradient-warning' : 'gradient-info'"
        @click="isActiveFilter = true"
      >
        <feather-icon icon="FilterIcon" size="25" />
      </b-button>
      <b-sidebar
        sidebar-class="sidebar-lg"
        bg-variant="white"
        v-model="isActiveFilter"
        right
        backdrop
        shadow
        no-header
      >
        <template #default="{ hide }" v-if="isActiveFilter">
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              content-sidebar-header
              px-2
              py-1
            "
          >
            <h5 class="mb-0">Filtros</h5>
            <div>
              <feather-icon
                class="ml-1 cursor-pointer"
                icon="XIcon"
                size="16"
                @click="hide"
              />
            </div>
          </div>
          <b-form class="px-1 mt-1">
            <b-row>
              <b-col cols="12">
                <b-form-group label="Data">
                  <flat-pickr
                    v-model="dataFilter"
                    class="form-control"
                    :config="config"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Empresa">
                  <v-select
                    v-if="companies.length > 0"
                    v-model="companieSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="companies"
                    autocomplete="off"
                    multiple
                    :clearable="false"
                  >
                    <template v-slot:option="option">
                      <div class="d-flex justify-content-between">
                        <h5 class="mb-0 text-uppercase">
                          {{ option.label }}
                        </h5>
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label="Estúdio">
                  <v-select
                    v-model="studioFilterSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="studiosPage"
                    :clearable="true"
                    autocomplete="off"
                    @search="
                      (res) => {
                        filterStudios(res);
                      }
                    "
                    :loading="isLoadingFilterStudio"
                    multiple
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        Pesquisando... <em>{{ search }}</em>
                      </template>
                      <em style="opacity: 0.5" v-else>Digite o estúdio</em>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label="Grupo de Estúdio">
                  <v-select
                    v-model="studiosGroupFilterSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="studiosGroup"
                    autocomplete="off"
                    :clearable="false"
                    multiple
                  >
                    <template v-slot:option="option">
                      <div class="d-flex justify-content-between">
                        <h5 class="mb-0 text-uppercase">
                          {{ option.label }}
                        </h5>
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label="Localidade">
                  <v-select
                    v-model="placesFilterSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="places"
                    autocomplete="off"
                    :clearable="false"
                    multiple
                  >
                    <template v-slot:option="option">
                      <div class="d-flex justify-content-between">
                        <h5 class="mb-0 text-uppercase">
                          {{ option.label }}
                        </h5>
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col cols="12" class="mb-1">
                <b-form-group label="Operador">
                  <v-select
                    v-if="operators.length > 0"
                    v-model="operatorsFilterSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="operators"
                    autocomplete="off"
                    :clearable="false"
                    multiple
                  >
                    <template v-slot:option="option">
                      <div class="d-flex justify-content-between">
                        <h5 class="mb-0 text-uppercase">
                          {{ option.label }}
                        </h5>
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col cols="9">
                <b-button @click="filter" variant="gradient-info" block>
                  FILTRAR
                </b-button>
              </b-col>
              <b-col cols="3">
                <b-button @click="clearfilter" variant="gradient-primary" block>
                  Limpar
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </template>
      </b-sidebar>
    </b-overlay>
  </b-card>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import Table from "@/components/Table.vue";
import { VBToggle } from "bootstrap-vue";
import _appointmentService from "@/services/appointment-service";
import _operatorsService from "@/services/operators-service";
import _companyService from "@/services/company-service";
import _studiosGroupService from "@/services/studios-group-service";
import _studioService from "@/services/studios-service";
import _placesService from "@/services/places-service";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import _appointmentStudioService from "@/services/appointment-studio-service";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    Table,
  },
  props: {
    projectId: {
      type: Object,
      default: null,
    },
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      isActiveFilter: false,
      isActiveChangeStudio: false,
      more: false,
      isloading: false,
      isLoadingStudio: false,
      isLoadingFilterStudio: false,
      ignorePreparation: false,
      ignorePreparationCS: false,
      currentePage: 1,
      search: "",
      size: this.$utils.paginationSize(),
      fields: [
        { key: "appointment_id", label: "ID", orderBy: { type: "back" } },
        { key: "company", label: "Empresa", orderBy: { type: "back" } },
        { key: "start", label: "Início", orderBy: { type: "back" } },
        { key: "end", label: "Fim", orderBy: { type: "back" } },
        {
          key: "course_event",
          label: "Curso/Evento",
          orderBy: { type: "back" },
        },
        {
          key: "studio_name",
          label: "Estúdio",
          class: "td-min-column",
          orderBy: { type: "back" },
        },
        {
          key: "operator_name",
          label: "Operador",
          class: "td-min-column",
          orderBy: { type: "back" },
        },
        {
          key: "operator_backup_name",
          label: "Backup",
          class: "td-min-column",
        },
      ],
      list: [],
      debounceMilliseconds: 300,
      timeout: null,
      companies: [],
      companieSelected: [],
      places: [],
      placesFilterSelected: [],
      studios: [],
      studiosPage: [],
      studioFilterSelected: [],
      studiosGroup: [],
      studiosGroupFilterSelected: [],
      operators: [],
      operatorsFilterSelected: [],
      dataFilter: null,
      config: {
        dateFormat: "d-m-Y",
        altFormat: "d/m/Y",
        minDate: "today",
        locale: Portuguese,
      },
      appointmentsFirst: [],
      appointmentFirstSelected: [],
      appointmentsSecond: [],
      appointmentSecondSelected: [],
      appointmentsFirst: [],
      appointmentsSecond: [],

      ascOrDes: true,
      orderByKey: "",
    };
  },
  created() {
    this.initiDateNow();
    this.getOperators();
    this.getStudiosGroup();
    this.getPlaces();
    this.getCompanies();
    this.getChangeStudios();
  },
  computed: {
    containsFilter() {
      var companyid = this.companieSelected
        ? this.companieSelected.map((m) => m.value)
        : ["-1"];

      var studiosid = this.studioFilterSelected
        ? this.studioFilterSelected.map((m) => m.value)
        : ["-1"];

      var operadorsid = this.operatorsFilterSelected
        ? this.operatorsFilterSelected.map((m) => m.value)
        : ["-1"];

      var groupstudioid = this.studiosGroupFilterSelected
        ? this.studiosGroupFilterSelected.map((m) => m.value)
        : ["-1"];

      var placesid = this.placesFilterSelected
        ? this.placesFilterSelected.map((m) => m.value)
        : ["-1"];

      return (
        this.dataFilter ||
        companyid.some((s) => s !== "-1") ||
        studiosid.some((s) => s !== "-1") ||
        groupstudioid.some((s) => s !== "-1") ||
        operadorsid.some((s) => s !== "-1") ||
        placesid.some((s) => s !== "-1")
      );
    },
  },
  methods: {
    initiDateNow() {
      const today = new Date();
      this.dataFilter = `${today.getDate()}-${
        today.getMonth() + 1
      }-${today.getFullYear()}`;
    },
    getOperators() {
      _operatorsService
        .showOperatorsWithUser()
        .then((res) => {
          if (res.content && res.content.length > 0) {
            this.operators = this.$utils.populardropdown(
              res.content,
              "name",
              "id",
              false,
              false,
              false
            );
            this.getRecords(this.currentePage);
          } else {
            this.$utils.toastError(
              "Notificação",
              "Sem nenhum operador encontrado."
            );
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    getRecords(_page, params = null) {
      this.isloading = true;
      _appointmentService
        .showScaleOperators(_page, this.size, params)
        .then((res) => {
          if (res.content) {
            res.content.forEach((element) => {
              element.operators = this.operators;
              element.operatorsSelected = null;
              element.companies = this.companies;
              element.companieSelected = null;
              element.studios = this.studios;
              element.studioFilterSelected = null;
              element.studiosGroup = this.studiosGroup;
              element.studiosGroupFilterSelected = null;
              element.places = this.places;
              element.placesFilterSelected = null;
            });
            this.more = res.content.length >= this.size;
            this.list.push(...res.content);
            this.currentePage = _page;
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    getChangeStudios() {
      this.isloading = true;
      _appointmentService
        .showScaleOperators(1, 9999, null)
        .then((res) => {
          if (res.content) {
            res.content.forEach((element) => {
              if (element.studio_main) {
                this.appointmentsFirst.push({
                  value: element.appointment_id,
                  label: element.appointment_id + ": " + element.studio_name,
                  studio_id: element.studio_id,
                });
                this.appointmentsSecond.push({
                  value: element.appointment_id,
                  label: element.appointment_id + ": " + element.studio_name,
                  studio_id: element.studio_id,
                });
              }
            });
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    getLoadMore(_page) {
      let params = this.getParams();
      this.getRecords(this.currentePage + 1, params);
    },
    getParams() {
      let params = {};
      if (this.dataFilter) {
        let dates = this.dataFilter.split("-");
        params.date = `${dates[2]}-${dates[1]}-${dates[0]}`;
      }

      if (this.companieSelected && this.companieSelected.length > 0) {
        params.company_id = [];
        this.companieSelected.forEach((element) => {
          params.company_id.push(element.value);
        });
      }

      if (this.studioFilterSelected && this.studioFilterSelected.length > 0) {
        params.studio_id = [];
        this.studioFilterSelected.forEach((element) => {
          params.studio_id.push(element.value);
        });
      }

      if (
        this.studiosGroupFilterSelected &&
        this.studiosGroupFilterSelected.length > 0
      ) {
        params.group_studio_id = [];
        this.studiosGroupFilterSelected.forEach((element) => {
          params.group_studio_id.push(element.value);
        });
      }

      if (this.placesFilterSelected && this.placesFilterSelected.length > 0) {
        params.place_id = [];
        this.placesFilterSelected.forEach((element) => {
          params.place_id.push(element.value);
        });
      }

      if (
        this.operatorsFilterSelected &&
        this.operatorsFilterSelected.length > 0
      ) {
        params.operator_id = [];
        this.operatorsFilterSelected.forEach((element) => {
          params.operator_id.push(element.value);
        });
      }

      params.orderByKey = this.orderByKey;
      params.ascOrDes = this.ascOrDes;

      return params;
    },
    getRecordsOrderBy(_params) {
      this.ascOrDes = _params.ascOrDes;
      this.orderByKey = _params.orderByKey;
      let qtd = this.currentePage;
      this.currentePage = 1;
      this.list = [];
      let params = this.getParams();
      for (let i = 0; i < qtd; i++) {
        this.getRecords(this.currentePage, params);
      }
    },
    filter() {
      this.currentePage = 1;
      this.list = [];
      let params = this.getParams();
      this.getRecords(this.currentePage, params);
    },
    onBlurBackoffice(item, backup, cancel) {
      let upsert = {
        studiosOperator: {},
      };
      this.list.forEach((element) => {
        if (element.appointment_studio_id == item.appointment_studio_id) {
          if (cancel) {
            if (!backup) {
              element.operator_id = 0;
              element.operator_name = "";
              item.operatorsSelected = null;
            } else {
              element.operator_backup_id = 0;
              element.operator_backup_name = "";
              item.operatorsBackupSelected = null;
            }
          } else {
            if (!backup) {
              element.operator_id = item.operatorsSelected.value;
              element.operator_name = item.operatorsSelected.label;
              item.operatorsSelected = null;

              //Preencher
              upsert.studiosOperator.id = item.appointment_operator_id;
              upsert.studiosOperator.operator_id = element.operator_id;
              upsert.studiosOperator.operator_backup = false;
              upsert.studiosOperator.appointment_studio_id =
                item.appointment_studio_id;
              if (
                upsert.studiosOperator.id == null ||
                upsert.studiosOperator.id == 0
              ) {
                upsert.example_id = true;
                upsert.studiosOperator.id = item.appointment_operator_backup_id;
              }
            } else {
              element.operator_backup_id = item.operatorsBackupSelected.value;
              element.operator_backup_name = item.operatorsBackupSelected.label;
              item.operatorsBackupSelected = null;

              //Preencher
              upsert.studiosOperator.appointment_studio_id =
                item.appointment_studio_id;
              //upsert.studiosOperator.id = item.operatorsSelected.value;
              //upsert.studiosOperator.operator_id = item.operatorsSelected.value;
              upsert.studiosOperator.operator_backup = true;
              upsert.studiosOperator.operator_backup_id =
                element.operator_backup_id;

              if (
                upsert.studiosOperator.id == null ||
                upsert.studiosOperator.id == 0
              ) {
                upsert.example_id = true;
                upsert.studiosOperator.id = item.appointment_operator_id;
              }
            }
          }
        }
      });

      if (!cancel) {
        const payload = { data: { ...upsert } };
        //promisse
        const _createOrUpdate =
          _appointmentService.upSertScaleOperators(payload);

        this.isloading = true;
        _createOrUpdate
          .then((res) => {
            this.list = [];
            this.appointmentsFirst = [];
            this.appointmentsSecond = [];
            let params = this.getParams();
            this.getRecords(this.currentePage, params);
            this.$utils.toast("SUCESSO", "Operador vinculado com sucesso");
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.isloading = false));
      }
    },
    getCompanies() {
      _companyService
        .showAll()
        .then((res) => {
          if (res.content && res.content.length > 0) {
            this.companies = this.$utils.populardropdown(
              res.content,
              "name",
              "id",
              false,
              false,
              false
            );
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    searchStudios(search, _appointment) {
      if (search && search.length >= 3) {
        if (!this.isLoadingStudio) {
          this.isLoadingStudio = true;

          setTimeout(() => {
            _appointmentService
              .showAllStudios(
                search,
                _appointment.start_date,
                _appointment.duration,
                _appointment.homeoffice,
                _appointment.appointment_id,
                _appointment.event_id,
                _appointment.presential,
                _appointment.jobtype_id,
                _appointment.lesson_id,
                this.ignorePreparation,
                true // no cache
              )
              .then((res) => {
                if (res.content && res.content.length > 0) {
                  this.studios = [];
                  res.content.forEach((element) => {
                    this.studios.push({
                      value: element.id,
                      label: element.name,
                    });
                  });
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.isLoadingStudio = false));
          }, 1000);
        }
      }
    },
    getStudiosGroup() {
      _studiosGroupService
        .showAll()
        .then((res) => {
          if (res.content && res.content.length > 0) {
            res.content.forEach((element) => {
              if (element.place && element.place.name) {
                element.name = `${element.place.name} - ${element.name}`;
              }
            });
            this.studiosGroup = this.$utils.populardropdown(
              res.content,
              "name",
              "id",
              false,
              false,
              false
            );
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    getPlaces() {
      _placesService
        .show()
        .then((res) => {
          if (res.content && res.content.length > 0) {
            this.places = this.$utils.populardropdown(
              res.content,
              "name",
              "id",
              false,
              false,
              false
            );
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    onBlurStudioChange(item) {
      let upsertStudio = {
        studios: {},
      };

      // selecionou estudio
      if (item.studioSelected) {
        // set parametros
        upsertStudio.appointment_id = item.appointment_id;
        upsertStudio.studio_id = item.studioSelected.value;
        upsertStudio.studio_id_removed = item.appointment_studio_id;

        // payload
        const payload = { data: { ...upsertStudio } };

        //chama api
        this.isloading = true;
        _appointmentService
          .updateStudio(payload)
          .then((res) => {
            this.list = [];
            this.getRecords(this.currentePage);
            this.$utils.toast("SUCESSO", "Studio vinculado com sucesso");
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.isloading = false));
      }
    },
    clearChangeStudio() {
      this.appointmentFirstSelected = null;
      this.appointmentSecondSelected = null;
      this.ignorePreparationCS = false;
    },
    changeStudio() {
      let upsertStudio = {};

      // selecionou estudio
      if (this.appointmentFirstSelected && this.appointmentSecondSelected) {
        upsertStudio.first_appointment_id = this.appointmentFirstSelected.value;
        upsertStudio.first_studio_id = this.appointmentFirstSelected.studio_id;

        upsertStudio.second_appointment_id =
          this.appointmentSecondSelected.value;
        upsertStudio.second_studio_id =
          this.appointmentSecondSelected.studio_id;

        // Ignorar tempo de preparação
        upsertStudio.ignore_preparation = this.ignorePreparationCS;

        const payload = { data: { ...upsertStudio } };

        //chama api
        this.isloading = true;
        _appointmentService
          .changeStudio(payload)
          .then((res) => {
            this.list = [];
            this.appointmentsFirst = [];
            this.appointmentsSecond = [];
            this.getRecords(this.currentePage);
            this.$utils.toast("SUCESSO", "Studio vinculado com sucesso");
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.isloading = false));
      }

      this.appointmentFirstSelected = null;
      this.appointmentSecondSelected = null;
      this.ignorePreparationCS = false;
      this.isActiveChangeStudio = false;
    },
    appointmentOpen(id) {
      if (this.$utils.isPermited("permission.appointment.edit")) {
        this.$router.push({
          path: `/pedagogic/calendar?q=${id}`,
        });
      }
    },
    onBlurStudio(item) {
      this.list.forEach((element) => {
        if (element.appointment_studio_id == item.appointment_studio_id) {
          element.studio_id = 0;
          element.studio_name = "";
          item.studioSelected = null;
          this.studios = [];
        }
      });
    },
    filterStudios(_search) {
      if (_search && _search.length >= 3) {
        if (!this.isLoadingFilterStudio) {
          this.isLoadingFilterStudio = true;
          setTimeout(() => {
            _studioService
              .findByName(_search)
              .then((res) => {
                if (res.content && res.content.length > 0) {
                  this.studiosPage = [];
                  res.content.forEach((element) => {
                    this.studiosPage.push({
                      value: element.id,
                      label: element.name,
                    });
                  });
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.isLoadingFilterStudio = false));
          }, 1500);
        }
      }
    },
    clearfilter() {
      this.initiDateNow();
      this.companieSelected = null;
      this.studioFilterSelected = null;
      this.studiosGroupFilterSelected = null;
      this.placesFilterSelected = null;
      this.operatorsFilterSelected = null;
      this.filter();
    },
    controlSizeName(name) {
      let newName = "";
      if (name) {
        var names = name.split(" ");
        names.forEach((element) => {
          newName += `${element} <br>`;
        });
        newName = newName.slice(0, -1);
      }
      return newName;
    },
  },
};
</script>
<style >
.td-min-column {
  min-width: 250px;
}

.btn-change-studio {
  position: fixed;
  bottom: 12%;
  right: 45px;
  z-index: 99;
}

.btn-filter-appoitment {
  position: fixed;
  bottom: 5%;
  right: 45px;
  z-index: 99;
}

.ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s linear;
  max-width: 250px;
  padding: 0.5rem 0rem;
}

.ellipsis:focus,
.ellipsis:hover {
  color: transparent;
}

.ellipsis:focus:after,
.ellipsis:hover:after {
  content: attr(data-text);
  overflow: visible;
  text-overflow: inherit;
  background: #fff;
  position: absolute;
  left: auto;
  top: auto;
  width: auto;
  border: 1px solid #eaebec;
  padding: 0 0.5rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
  white-space: nowrap;
  word-wrap: break-word;
  display: block;
  color: black;
  margin-top: -1.25rem;
}

.cont-operator {
  margin-left: 13px;
  padding: 7px;
  padding-left: 15px;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  width: 90%;
}

.cont-studio {
  margin-left: 13px;
  padding: 7px;
  padding-left: 9px;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  width: 90%;
}

.alert-icon {
  display: block;
  position: absolute;
  top: 5px;
  left: -10px;
  background-color: #f5912c;
  color: white;
  border-radius: 10px;
  padding: 3px;
  width: 18px;
  height: 18px;
}

.del-icon {
  display: block;
  position: absolute;
  top: 7px;
  right: 7px;
}
</style>