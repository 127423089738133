import HttpService from './HttpService'

const _http = new HttpService()
export default {
  showScaleModerators: (page, size, params = null) => {
    let _params = `?size=${size}&page=${page - 1}&asc&order_by=appointment.start`;
    if (params) {
      if (params.date)
        _params += `&data.date=${params.date}`;
      if (params.company_id)
        _params += `&data.company_id=${params.company_id}`;
      if (params.group_studio_id)
        _params += `&data.group_studio_id=${params.group_studio_id}`;
      if (params.studio_id)
        _params += `&data.studio_id=${params.studio_id}`;
      if (params.moderator_id)
        _params += `&data.moderator_id=${params.moderator_id}`;
      if (params.job_type_id)
        _params += `&data.job_type_id=${params.job_type_id}`;
      if (params.transmission_type_id)
        _params += `&data.transmission_type_id=${params.transmission_type_id}`;
    }
    return _http.get(`/api/appointmentStudios/scale-moderators${_params}`)
  },
  getStatusList: () => {
    return _http.get('/api/appointmentStudios/status')
  },
  updateStatus: (payload) => {
    return _http.post('/api/appointmentStudios/status', payload)
  },
  update: (payload) => {
    return _http.put('/api/appointmentStudios', payload)
  },
  insertModerator: (payload) => {
    return _http.post('/api/appointmentStudios/moderator', payload)
  },
  deleteModerator: (id) => {
    return _http.delete(`/api/appointmentStudios/moderator/${id}`)
  }
}